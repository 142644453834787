<template>
  <div class="login-wrapper">
    <loader :loading="loading" />
    <div class="d-flex flex-column flex-lg-row align-items-center mt-5 mt-lg-0">
      <div class="logo me-0 me-lg-5 mb-5 mb-lg-0">
        <h6 class="text-white mb-4">Painel de administração</h6>
        <img src="@/assets/logo-white.svg"/>
      </div>
      <div class="form ms-0 ms-lg-5">
        <div class="errors" v-if="error">
          <p class="alert alert-danger">Usuário ou senha inválidos</p>
        </div>

        <div class="form-floating mb-3">
          <input type="email" class="form-control" v-model="payload.email">
          <label>Email</label>
        </div>
        <div class="form-floating">
          <input type="password" class="form-control" v-model="payload.password">
          <label>Senha</label>
        </div>

        <a href="#" @click.prevent=""
           class="d-flex align-items-center justify-content-center mb-4 mt-4 login-link font-size-default">
          Esqueceu sua senha?
        </a>

        <button class="btn btn-primary btn-block mt-3" @click="handleLogin">Entrar</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import Loader from "@/components/shared/loader.vue";

export default {
  name: 'login',
  components: {Loader},
  props: {},
  data() {
    return {
      loading: false,
      error: false,
      payload: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async handleLogin() {
      this.loading = true;
      this.error = false;
      await this.login(this.payload).then(response => {
      }).catch(e => {
        this.error = true;
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.login-wrapper {
  display: flex;
  justify-content: center;
  height: calc((var(--vh, 1vh) * 100));
  width: 100vw;
  background: var(--primary);
  padding: 1rem;

  @media (min-width: 992px) {
    align-items: center;
  }
}
.logo {
  img {
    width: 220px;

    @media (min-width: 992px) {
      width: 300px;
    }
  }
}

.form {
  background: var(--white);
  border-radius: .32rem;
  padding: 2rem;
  width: 90vw;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .2);

  @media (min-width: 992px) {
    max-width: 360px;
  }

  .btn {
    height: 55px;
    margin-bottom: 0.5rem !important;
    font-weight: 700 !important;
    font-size: 1rem;
  }

  .login-link {
    color: #666;

    &:hover {
      color: var(--default-text-color);
    }
  }
}

.title {
  font-size: 22px;
  font-weight: 700;
}
</style>
